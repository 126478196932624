/* Google Font */
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Baloo+Chettan+2:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bitter&display=swap");
.btn,
.jumbotron,
.nav :hover {
  border-radius: 0px;
}
.contact {
  font-family: "Roboto Slab", serif;
}
.navbar-brand span {
  position: absolute;
  margin-left: 0.6em;
  margin-top: 0.2em;
  font-size: 1.3em;
  color: #ffffff;
  font-family: "Roboto Slab", serif;
  /* font-family: TimesNewRoman; */
}
.nav-item {
  padding-right: 1em;
  font-size: 1.1em;
  font-family: "Bitter", serif;
}
.navbar {
  padding: 15px;
}
.navbar-home {
  background: linear-gradient(to right, #ff2929, #f8f81f);
}
.home {
  background: #ff2929;
  /* background: red; */

  background: linear-gradient(to right, #ff2929, #f8f81f);
  /* background: linear-gradient(to right, skyblue, #ffffff); */
  height: 86vh;
  width: 100%;
  overflow: hidden;
}
.header-img {
  margin-top: -2.8em;
  background-image: url(./header.png);
}

.head-title {
  margin-top: 10rem;
  color: #ffff;
  font-family: "Baloo Chettan 2", cursive;
}

@media (max-width: 640px) {
  .ketan {
    display: none;
  }
}
.col-lg-6 .container {
  margin-top: 5em;
  /* margin-bottom: 8em; */
}
.col-lg-6 .container h1 {
  font-family: "Poppins", sans-serif;
  color: #ff2929;
}
.col-lg-6 .container h1 span {
  font-weight: bold;
  font-size: 3rem;
}

.col-lg-6 .container h3 {
  font-family: "Poppins", sans-serif;
  color: #ff2929;
}
.col-lg-6 .container h3 span {
  background-color: #ff2929;
  color: white;
  padding-right: 10px;
}
.btn {
  border-radius: 10px;
  padding: 10px;
}
.redBorder {
  position: absolute;
  width: 5rem;
  margin-right: 2em;
  margin-top: 1em;
}

.YellowBorder {
  /* position: absolute; */
  width: 5rem;

  /* margin-top: -2.2em; */
  float: right;
  /* margin-bottom: 5em; */
  /* margin-left: 32em; */

  /* margin-right: 5em; */
}

@media only screen and (max-width: 960px) {
  .hmm {
    display: flex;
    flex-direction: column-reverse;
  }
}
.slider {
  margin-top: 12rem;
  height: 100px;
  /* padding-left: 15px; */
  overflow: hidden;
}

.slider div {
  font-family: "Poppins", sans-serif;
  letter-spacing: 8px;
  font-size: 2em;
  height: 50px;
  padding: 2px 15px;
  text-align: center;
  color: white;
  margin-bottom: 50px;
}

.slider-text1 {
  /* background-color: #ff2929; */

  animation: slide 5s linear infinite;
}
.slider-text2 {
  /* background-color: #ff2929; */
}

.slider-text3 {
}
@keyframes slide {
  0% {
    margin-top: -300px;
  }
  5% {
    margin-top: -200px;
  }
  33% {
    margin-top: -200px;
  }
  38% {
    margin-top: -100px;
  }
  66% {
    margin-top: -100px;
  }
  71% {
    margin-top: 0px;
  }
  100% {
    margin-top: 0px;
  }
}

/* footer start here */
footer {
  background: rgba(0, 0, 0, 0.815);
  overflow-x: hidden;
  padding: 14vmin 18vmin;
}

footer p > span {
  color: #ff512f;
}

footer input {
  border: none !important;
}

footer .input-group .input-group-text {
  background: var(--bggradient);
  border: none;
}

footer .column i {
  color: #f8f81f;
}

footer .column i + i {
  padding: 0 0.5em;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.815);
  z-index: 9999;
  transition: all 1.5s ease;
}
.fa {
  color: #f8f81f !important;
}
.text-light {
  font-family: "Poppins", sans-serif;
}

/* footer end here */
.borders {
  position: absolute;
  margin-top: 6em;
  transform: rotate(180deg);
  /* width: 22em; */
}
.border1 {
  position: absolute;
  margin-top: 20em;
  /* width: 22em; */
}

/* star css */
input[type="radio"] {
  display: none;
}

.star {
  cursor: pointer;
  transition: color 100ms;
}

/* end star css */

.rating-center {
  margin-top: 12em;
  border: 1px solid red;
}
/* Logo carousel */

.slick-slide {
  margin: 0px 20px;
}

.slick-slide img {
  width: 100%;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.row {
  padding: 20px;
}
.brand-logo {
  /* height: 100px; */
  padding: 10px;
}
.corporates {
  background: #ff2929;
  /* background: red; */

  background: linear-gradient(to right, #ff2929, #f8f81f);
  padding: 10px;
}
.customer {
  font-family: "Poppins", sans-serif;
  color: white;
}
.corporate-mobile {
  display: none;
}
.footer-logo {
  margin-top: -0.4em;
  margin-right: 10px;
}
.footer-logo-name {
  font-size: 1.5em;
}
/* Responsive css */
@media only screen and (max-width: 768px) {
  .borders {
    position: absolute;
    margin-top: 2em;
    transform: rotate(180deg);
    width: 22em;
  }
  .border1 {
    position: absolute;
    margin-top: 13em;
    width: 22em;
  }
  .slider {
    margin-top: 6em;
  }
  .home {
    height: 81vh;
  }
  .mobile-header-img {
    float: bottom;
    bottom: 0;
    margin-top: 4em;
    margin-left: -2em;
  }

  .header-img {
    display: none;
  }
  .customer {
    font-size: 1.2em;
  }
  .corporate {
    display: none;
  }
  .corporate-mobile {
    display: inline-block;
  }
  .footer-logo-name {
    font-size: 1.1em;
  }
}
/* new css */
.bg-descrip {
  background: linear-gradient(to right, #ff2929, #f8f81f);
}

.btn-price {
  font-size: 20px !important ;
  background-color: #ff2929 !important;
  color: #ffffff !important;
}

.rating-page {
  margin-top: 10em;
}

/* whatsapp */

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  /* box-shadow: 2px 2px 3px #999; */
  z-index: 100;
}
.float:hover {
  color: #000;
}

.my-float {
  margin-top: 1px;
}


/* Fix Outer */
html,
body {
  overflow-x: hidden;
}

/* end */

@media only screen and (min-width: 1440px) {
  .header-img {
    margin-top: 1em;
    background-image: url(./header.png);
  }
}
